import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import LoginForm from '@/components/LoginForm.vue';
import componentI18n from '@/i18n/componentI18n';
import router from '@/router';
export default defineComponent({
    name: 'Login',
    ...componentI18n('views.auth.sign_in'),
    components: {
        LoginForm
    },
    data() {
        return {
            requirePermanent: this.$route.params.requirePermanent,
            redirect: this.$attrs.redirect || '/'
        };
    },
    computed: {
        ...mapGetters(['loggedIn', 'session']),
        heading() {
            return this.redirect.includes('retro') ? this.$t('sign_in_to_join') : this.$t('title');
        }
    },
    mounted() {
        if (this.loggedIn) {
            router.push({ name: 'home' });
        }
    },
    methods: {
        handleLoginSuccess() {
            router.push(this.redirect);
        }
    }
});
